import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	manualEnquiry: null,
	referralCode: null,
	result: null,
	tempFiles: [],
};

const manualSlice = createSlice({
	name: "manual",
	initialState,
	reducers: {
		setManualEnquiry(state, action) {
			return Object.assign(state, { manualEnquiry: action.payload });
		},
		setManualReferralCode(state, action) {
			return Object.assign(state, { referralCode: action.payload });
		},
        setResult(state, action) {
			return Object.assign(state, { result: action.payload });
		},
		setTempFiles: (state, action) => { // Add this reducer
			state.tempFiles = action.payload;
		},
		clearTempFiles: (state) => { // Add this reducer
			state.tempFiles = [];
		},
		resetManualMotor() {
            
			return initialState;
		},
	},
});

export const { setManualEnquiry, setManualReferralCode, setResult, resetManualMotor, setTempFiles, clearTempFiles } = manualSlice.actions;

export default manualSlice.reducer;